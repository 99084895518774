import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';

import { Box, SxProps, Theme } from '@mui/material';

import { Colors } from '../../../../design/theme';
import { chatSelector } from '../../../../redux/chat/chat.selector';

interface AvatarAudioDotsProps {
  sx?: SxProps<Theme>;
}

type DotHeights = [number, number, number];

const AvatarAudioDots = ({ sx }: AvatarAudioDotsProps) => {
  const initialHeight: DotHeights = [4, 4, 4];
  const [heights, setHeights] = useState(initialHeight);
  const { isPlayingAudio } = useSelector(chatSelector);

  useEffect(() => {
    if (isPlayingAudio) {
      const intervalId = setInterval(() => {
        setHeights([
          Math.floor(Math.random() * 10) + 3,
          Math.floor(Math.random() * 10) + 5,
          Math.floor(Math.random() * 10) + 3
        ]);
      }, 100);

      return () => clearInterval(intervalId);
    } else {
      setHeights(initialHeight);
    }
  }, [isPlayingAudio]);

  return (
    <Box
      sx={{
        width: '2rem',
        height: '2rem',
        background: Colors.primary.main,
        borderRadius: '50%',
        display: 'flex',
        gap: '3px',
        justifyContent: 'center',
        alignItems: 'center',
        ...sx
      }}>
      {heights.map((height, index) => (
        <Box
          key={index}
          sx={{
            width: '4px',
            height: `${height}px`,
            backgroundColor: Colors.white,
            borderRadius: '100%',
            willChange: 'transform'
          }}
        />
      ))}
    </Box>
  );
};

export default AvatarAudioDots;
