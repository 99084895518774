import { useEffect, useRef, useState } from 'react';
import ReactMarkdown from 'react-markdown';
import { useSelector } from 'react-redux';

import { Box, Divider, Paper } from '@mui/material';

import { Colors } from '../../../design/theme';
import useIsLargeScreen from '../../../hooks/useIsLargeScreen';
import { chatSelector } from '../../../redux/chat/chat.selector';
import { ChatMessage } from '../types/chat-message.type';
import AudioChatBoxMobile from './AudioChatBoxMobile';
import ErrorAction from './ui/ErrorAction';
import PulsingDots from './ui/PulsingDotsIcon';

const AudioChatBox = () => {
  const chatEndRef = useRef<HTMLDivElement | null>(null);
  const isLargeScreen = useIsLargeScreen();
  const { messages, isRecording, isLoadingResponse } =
    useSelector(chatSelector);
  const [lastMessage, setLastMessage] = useState<ChatMessage | null>(null);

  useEffect(() => {
    if (messages.length > 0 && messages[messages.length - 1].sender === 'ai') {
      setLastMessage(messages[messages.length - 1]);
    }
  }, [messages]);

  useEffect(() => {
    chatEndRef.current?.scrollIntoView({ behavior: 'smooth' });
  }, [lastMessage, isRecording]);

  if (!isLargeScreen) return <AudioChatBoxMobile lastMessage={lastMessage} />;

  return (
    <Box
      sx={{
        width: '100%',
        minHeight: '100%',
        display: 'grid',
        placeItems: 'center',
        justifyContent: 'center',
        alignItems: 'center',
        paddingY: '1.5rem',
        '&::-webkit-scrollbar': {
          scrollbarWidth: 'none',
          width: '8px',
          borderRadius: '6px'
        },
        '&::-webkit-scrollbar-thumb': {
          backgroundColor: Colors.grey,
          borderRadius: '6px',
          width: '2px'
        },
        paddingBottom: '1.8rem'
      }}>
      <Paper
        sx={{
          maxWidth: '33.25rem',
          fontSize: '1rem',
          fontFamily: 'Nunito',
          fontWeight: 500,
          textAlign: 'left',
          paddingX: '1rem',
          paddingTop: '0.5rem',
          paddingBottom: '1rem',
          borderRadius: '1rem',
          backgroundColor: Colors.softWhite,
          color: Colors.neutralGrey
        }}>
        {isLoadingResponse ? (
          <PulsingDots
            sx={{
              marginTop: '1rem',
              width: '12rem'
            }}
          />
        ) : (
          <>
            <ReactMarkdown>{lastMessage?.text}</ReactMarkdown>
            {lastMessage?.error && (
              <>
                {lastMessage.text && <Divider />}
                <ErrorAction
                  messageError={lastMessage.error}
                  messageId={lastMessage.id}
                />
              </>
            )}
            <div ref={chatEndRef} />
          </>
        )}
      </Paper>
    </Box>
  );
};

export default AudioChatBox;
