import MoreHorizIcon from '@mui/icons-material/MoreHoriz';
import { SxProps, keyframes } from '@mui/system';

import { Colors } from '../../../../design/theme';

interface PulsingDotsProps {
  sx?: SxProps;
}

const pulseEffect = keyframes`
  0% { color: ${Colors.grey}; }
  50% { color: ${Colors.black}; }
  100% { color: ${Colors.grey}; }
`;

const PulsingDots = ({ sx }: PulsingDotsProps) => {
  return (
    <MoreHorizIcon
      sx={{
        color: Colors.grey,
        margin: '0 auto',
        width: '100%',
        animation: `${pulseEffect} 1s infinite`,
        ...sx
      }}
    />
  );
};

export default PulsingDots;
