import { ReactComponent as ErrorWarningIcon } from 'assets/images/ErrorWarning-icon.svg';
import React from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { Box, Button, Typography } from '@mui/material';

import { Colors } from '../../../../design/theme';
import { chatSelector } from '../../../../redux/chat/chat.selector';
import { retryMessage } from '../../../../redux/chat/chat.slice';
import { MessageError } from '../../enums/MessageError.enum';

interface ErrorActionProps {
  messageError: MessageError;
  messageId: string;
}

const ErrorAction: React.FC<ErrorActionProps> = ({
  messageError,
  messageId
}) => {
  const dispatch = useDispatch();
  const { isConnected } = useSelector(chatSelector);
  const canRetry =
    messageError === MessageError.AI_MESSAGE_ERROR && isConnected;

  let message = '';
  switch (messageError) {
    case MessageError.UNKNOWN_ERROR:
      message = `An error occurred while processing your request: ${messageId}`;
      break;
    case MessageError.AI_MESSAGE_ERROR:
      message = 'There was an error';
      break;
    case MessageError.USER_MESSAGE_ERROR:
      message = 'An error occurred while sending your message';
      break;
    case MessageError.CONNECTION_ERROR:
      message = 'Unable to connect with iAffirm';
      break;
    default:
      message = 'An error occurred';
      break;
  }

  return (
    <Box
      sx={{
        width: '100%',
        display: 'flex',
        justifyContent: 'space-between',
        items: 'center',
        gap: '1rem',
        paddingY: '1rem'
      }}>
      <Box
        sx={{
          paddingX: '0.5rem',
          height: '3rem',
          width: '3rem',
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          background: Colors.warningRed,
          borderRadius: '25%'
        }}>
        <ErrorWarningIcon />
      </Box>

      <Box
        sx={{
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'flex-start',
          justifyContent: 'center',
          minHeight: '3rem',
          width: '100%'
        }}>
        <Typography
          sx={{
            fontSize: '1rem',
            fontWeight: 500,
            color: Colors.green.dark
          }}>
          {message}
        </Typography>
        {canRetry && (
          <Button
            variant="text"
            sx={{
              margin: 0,
              padding: 0,
              fontWeight: 600,
              minWidth: 'auto',
              '&:hover': {
                color: Colors.bgGreen,
                background: 'none'
              }
            }}
            onClick={() => dispatch(retryMessage(messageId))}>
            Try again
          </Button>
        )}
      </Box>
    </Box>
  );
};

export default ErrorAction;
