export enum VoicesEleven {
  Adam = 'Adam',
  Francesca = 'Francesca',
  Ana = 'Ana',
  Jaxon = 'Jaxon',
  Christopher = 'Christopher',
  Serenity = 'Serenity',
  Brittney = 'Brittney',
  Danielle = 'Danielle',
  Dan = 'Dan',
  SoftDemure = 'Soft Demure Garden Voice',
  Sally = 'Sally'
}
