import { PayloadAction, createSlice } from '@reduxjs/toolkit';

import { ChatEvent } from '../../modules/avatar/enums/ChatEvent.enum';
import { MessageError } from '../../modules/avatar/enums/MessageError.enum';
import { MessageSender } from '../../modules/avatar/enums/MessageSender.enum';
import { ChatMessage } from '../../modules/avatar/types/chat-message.type';
import socket from '../../services/socket';
import { ChatState } from './types/chat-state.interface';

const initialState: ChatState = {
  messages: [],
  mode: 'text',
  isConnected: false,
  isError: false,
  isAnswering: false,
  isRecording: false,
  actionError: null,
  isLoadingResponse: false,
  isPlayingAudio: false
};

const chatSlice = createSlice({
  name: 'chat',
  initialState,
  reducers: {
    setMessages: (state, action: PayloadAction<ChatMessage[]>) => {
      state.messages = action.payload;
    },
    addMessage: (state, action: PayloadAction<ChatMessage>) => {
      state.messages.push(action.payload);
    },
    addIncomingMessage: (state, action: PayloadAction<ChatMessage>) => {
      const lastMessage = state.messages[state.messages.length - 1];

      if (
        lastMessage &&
        lastMessage.sender === MessageSender.AI &&
        lastMessage.id === action.payload.id &&
        action.payload.text
      ) {
        lastMessage.text += action.payload.text;
      } else {
        state.messages.push({
          sender: action.payload.sender,
          text: action.payload.text,
          id: action.payload.id
        });
      }
    },
    resetMessage: (state, action: PayloadAction<ChatMessage>) => {
      const lastMessage = state.messages[state.messages.length - 1];

      if (
        lastMessage &&
        lastMessage.sender === MessageSender.AI &&
        lastMessage.id === action.payload.id
      ) {
        lastMessage.text = '';
        lastMessage.error = null;
      } else {
        state.messages.push({
          error: null,
          sender: action.payload.sender,
          text: '',
          id: action.payload.id
        });
      }
    },
    setMode: (state, action: PayloadAction<'text' | 'voice'>) => {
      state.mode = action.payload;
    },
    setIsConnected: (state, action: PayloadAction<boolean>) => {
      state.isConnected = action.payload;
    },
    setIsError: (state, action: PayloadAction<boolean>) => {
      state.isError = action.payload;
    },
    setIsAnswering: (state, action: PayloadAction<boolean>) => {
      state.isAnswering = action.payload;
    },
    setIsRecording: (state, action: PayloadAction<boolean>) => {
      state.isRecording = action.payload;
    },
    setMessageError: (state, action: PayloadAction<ChatMessage>) => {
      const messageIndex = state.messages.findIndex(
        (msg) => msg.id === action.payload.id
      );
      if (messageIndex !== -1) {
        state.messages[messageIndex].error = action.payload.error;
      } else {
        state.messages.push(action.payload);
      }
      state.isAnswering = false;
    },
    retryMessage: (state, action: PayloadAction<string>) => {
      const messageIndex = state.messages.findIndex(
        (msg) => msg.id === action.payload
      );
      if (messageIndex !== -1) {
        state.isAnswering = true;
        const message = state.messages[messageIndex];
        message.error = null;
        socket.emit(ChatEvent.USER_RETRY_MESSAGE, message.id);
      }
    },
    removeConnectionErrorMessages: (state) => {
      state.messages = state.messages.filter(
        (message) => message.error !== MessageError.CONNECTION_ERROR
      );
      state.isAnswering = false;
    },
    setIsLoadingResponse: (state, action: PayloadAction<boolean>) => {
      state.isLoadingResponse = action.payload;
    },
    setIsPlayingAudio: (state, action: PayloadAction<boolean>) => {
      state.isPlayingAudio = action.payload;
    }
  }
});

export const {
  setMessages,
  addMessage,
  setMode,
  setIsConnected,
  setIsError,
  setIsAnswering,
  addIncomingMessage,
  setIsRecording,
  setMessageError,
  retryMessage,
  resetMessage,
  removeConnectionErrorMessages,
  setIsLoadingResponse,
  setIsPlayingAudio
} = chatSlice.actions;

export default chatSlice.reducer;
