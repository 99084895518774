import { ReactComponent as MicIcon } from 'assets/images/Mic-icon.svg';
import { ReactComponent as SendIcon } from 'assets/images/Send-chat-icon.svg';
import {
  RegExpMatcher,
  TextCensor,
  englishDataset,
  englishRecommendedTransformers
} from 'obscenity';
import { useEffect, useRef, useState } from 'react';
import { useDispatch } from 'react-redux';

import {
  Box,
  Button,
  IconButton,
  InputAdornment,
  TextField,
  Typography
} from '@mui/material';

import { Colors } from '../../../design/theme';
import useIsLargeScreen from '../../../hooks/useIsLargeScreen';
import { addMessage, setMode } from '../../../redux/chat/chat.slice';
import socket from '../../../services/socket';
import { ChatEvent } from '../enums/ChatEvent.enum';
import { MessageError } from '../enums/MessageError.enum';
import { MessageSender } from '../enums/MessageSender.enum';

const matcher = new RegExpMatcher({
  ...englishDataset.build(),
  ...englishRecommendedTransformers
});
const censor = new TextCensor();

const ChatInputText = ({ disabled }: { disabled: boolean }) => {
  const dispatch = useDispatch();
  const [inputValue, setInputValue] = useState('');
  const [censoredValue, setCensoredValue] = useState('');
  const inputRef = useRef<HTMLInputElement>(null);
  const isLargeScreen = useIsLargeScreen();

  useEffect(() => {
    if (!disabled && inputRef.current) {
      inputRef.current.focus();
    }
  }, [disabled]);

  const handleKeyDown = (event: React.KeyboardEvent) => {
    if (event.key === 'Enter') {
      handleSendMessage();
    }
  };

  const handleSendMessage = () => {
    if (censoredValue.trim()) {
      handleSend(censoredValue);
      setInputValue('');
      setCensoredValue('');
    }
  };

  const handleSend = (messageText: string) => {
    if (disabled) return;

    socket
      .timeout(3500)
      .emitWithAck(ChatEvent.USER_MESSAGE, messageText)
      .catch(() => {
        dispatch(
          addMessage({
            id: `error-${Date.now()}`,
            error: MessageError.USER_MESSAGE_ERROR,
            sender: MessageSender.AI,
            text: ''
          })
        );
      });
  };

  const handleInputChange = (
    event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => {
    const inputValue = event.target.value;
    setInputValue(inputValue);

    const matches = matcher.getAllMatches(inputValue);

    setCensoredValue(censor.applyTo(inputValue, matches));
  };

  return (
    <Box
      sx={{
        width: '100%',
        display: 'flex',
        alignItems: 'center',
        gap: '1rem',
        paddingX: isLargeScreen ? 0 : '1.115rem'
      }}>
      <TextField
        inputRef={inputRef}
        autoFocus={true}
        value={inputValue}
        onChange={(e) => handleInputChange(e)}
        onKeyDown={handleKeyDown}
        placeholder="Type your intention"
        fullWidth
        disabled={disabled}
        sx={{
          fontSize: !isLargeScreen ? '1rem' : '1.125rem',
          boxShadow: `0px 1px 1px 1px ${Colors.lightGrey}`,
          borderRadius: '3rem',
          backgroundColor: 'white',
          border: 'none',
          '& .MuiOutlinedInput-notchedOutline': {
            border: 'none'
          },
          paddingLeft: inputValue ? '1.25rem' : '0rem',
          input: { cursor: disabled ? 'not-allowed' : 'text' }
        }}
        InputProps={{
          style: {
            height: !isLargeScreen ? '3.375rem' : '5.25rem',
            borderRadius: '3rem',
            border: 'none'
          },
          startAdornment: !inputValue ? (
            <InputAdornment position="start" sx={{ marginLeft: '0.65rem' }}>
              <Typography
                component="span"
                sx={{
                  color: Colors.green.dark,
                  fontSize: '1.125rem',
                  fontWeight: 500
                }}>
                |
              </Typography>
            </InputAdornment>
          ) : null,
          endAdornment: inputValue ? (
            <InputAdornment position="end" sx={{ marginRight: '0.75rem' }}>
              <IconButton onClick={handleSendMessage} edge="start">
                <SendIcon style={{ width: '1.5rem', height: '1.5rem' }} />
              </IconButton>
            </InputAdornment>
          ) : null
        }}
      />

      <Box
        sx={{
          display: 'flex',
          alignItems: 'center',
          background: 'rgba(0, 0, 0, 0.1)',
          borderRadius: '50%',
          padding: isLargeScreen ? '1rem' : 0,
          zIndex: 30
        }}>
        <Button
          onClick={() => dispatch(setMode('voice'))}
          sx={{
            height: '3.375rem',
            width: '3.375rem',
            minWidth: '3.375rem',
            background: Colors.green.main,
            padding: 0,
            borderRadius: '50%',
            '&:hover': {
              backgroundColor: Colors.green.dark
            }
          }}>
          <MicIcon style={{ width: '1.5rem', height: '1.5rem', padding: 0 }} />
        </Button>
      </Box>
    </Box>
  );
};

export default ChatInputText;
