import { useSelector } from 'react-redux';

import { Box, SxProps, Theme } from '@mui/material';

import assistant from '../../../assets/images/AvatarChat/assistant.png';
import useIsLargeScreen from '../../../hooks/useIsLargeScreen';
import { chatSelector } from '../../../redux/chat/chat.selector';
import AvatarAudioDots from './ui/AvatarAudioDots';

interface StatusAvatarPictureProps {
  sx?: SxProps<Theme>;
}

const StatusAvatarPicture = ({ sx }: StatusAvatarPictureProps) => {
  const { mode, isRecording } = useSelector(chatSelector);
  const isLargeScreen = useIsLargeScreen();

  return (
    <Box sx={{ position: 'relative', width: 80, height: 80, ...sx }}>
      <Box
        sx={{ width: '100%', height: '100%' }}
        component={'img'}
        src={assistant}
        alt="Avatar assistant"
      />
      {mode === 'voice' && !isRecording && (
        <AvatarAudioDots
          sx={{
            position: 'absolute',
            bottom: isLargeScreen ? 0 : -15,
            right: isLargeScreen ? 0 : -15,
            ...(!isLargeScreen && { width: '2.5rem', height: '2.5rem' })
          }}
        />
      )}
    </Box>
  );
};

export default StatusAvatarPicture;
