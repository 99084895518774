import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';

import { Box, Grow } from '@mui/material';

import { chatSelector } from '../../../redux/chat/chat.selector';
import { MessageError } from '../enums/MessageError.enum';
import ChatInputText from './ChatInputText';
import ChatInputVoice from './ChatInputVoice';

const ChatInputWrapper = () => {
  const [isEnabled, setIsEnabled] = useState(true);
  const { mode, messages, isConnected, isAnswering, isPlayingAudio } =
    useSelector(chatSelector);

  useEffect(() => {
    const isLastMessageError =
      messages.length > 0 && messages[messages.length - 1]?.error;

    const shouldEnableInput =
      isConnected &&
      !isAnswering &&
      (isLastMessageError === MessageError.USER_MESSAGE_ERROR ||
        !isLastMessageError) &&
      !isPlayingAudio;

    setIsEnabled(shouldEnableInput);
  }, [messages, isConnected, isAnswering, isPlayingAudio]);

  return (
    <Box sx={{ width: '100%' }}>
      {mode === 'text' && (
        <Grow in={mode === 'text'} timeout={250}>
          <Box>
            <ChatInputText disabled={!isEnabled} />
          </Box>
        </Grow>
      )}

      {mode === 'voice' && (
        <Grow in={mode === 'voice'} timeout={250}>
          <Box>
            <ChatInputVoice disabled={!isEnabled} />
          </Box>
        </Grow>
      )}
    </Box>
  );
};

export default ChatInputWrapper;
