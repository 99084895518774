import { useEffect, useRef } from 'react';
import { useSelector } from 'react-redux';

import { useAppDispatch } from '../../../hooks/useAppDispatch';
import { chatSelector } from '../../../redux/chat/chat.selector';
import { setIsPlayingAudio } from '../../../redux/chat/chat.slice';

interface AudioChunk {
  data: ArrayBuffer | Uint8Array;
}

const useAudioPlayback = () => {
  const dispatch = useAppDispatch();
  const audioContextRef = useRef<AudioContext | null>(null);
  const audioBufferQueue = useRef<AudioBuffer[]>([]);
  const isPlayingRef = useRef<boolean>(false);
  const { mode } = useSelector(chatSelector);

  const playAudio = () => {
    if (!audioBufferQueue.current || audioBufferQueue.current.length === 0)
      return;
    dispatch(setIsPlayingAudio(true));
    isPlayingRef.current = true;
    const audioContext = audioContextRef.current;
    const audioBuffer = audioBufferQueue.current.shift();

    if (!audioContext || !audioBuffer) return;

    const source = audioContext.createBufferSource();
    source.buffer = audioBuffer;
    source.connect(audioContext.destination);
    source.start();

    source.onended = () => {
      if (audioBufferQueue.current.length > 0) {
        playAudio();
      } else {
        isPlayingRef.current = false;
        dispatch(setIsPlayingAudio(false));
      }
    };
  };

  useEffect(() => {
    const audioContext = new (window.AudioContext || window.AudioContext)();
    audioContextRef.current = audioContext;

    if (mode === 'text') stopAudio();

    return () => {
      stopAudio();
    };
  }, [mode]);

  const enqueueAudio = async (chunk: AudioChunk) => {
    try {
      if (!(chunk.data instanceof ArrayBuffer)) return;

      const uint8Array = new Uint8Array(chunk.data);

      if (audioContextRef.current === null) return;

      const audioBuffer = await audioContextRef.current.decodeAudioData(
        uint8Array.buffer
      );
      audioBufferQueue.current.push(audioBuffer);
      if (!isPlayingRef.current) {
        playAudio();
      }
    } catch (error) {
      console.error('Error decoding audio data:', error);
      stopAudio();
    }
  };

  const stopAudio = () => {
    if (audioContextRef.current) {
      audioContextRef.current.close().then(() => {
        audioContextRef.current = null;
        isPlayingRef.current = false;
      });
    }
    audioBufferQueue.current = [];
    dispatch(setIsPlayingAudio(false));
  };

  return { enqueueAudio };
};

export default useAudioPlayback;
