import { Box, SxProps, Theme, alpha, keyframes } from '@mui/material';

import { Colors } from '../../../../design/theme';

interface PulsingWavesProps {
  sx?: SxProps<Theme>;
}

const waveEffect = `
  0% {
    transform: scale(0.95);
    box-shadow: 0 0 0px 0 ${alpha(Colors.primary.main, 0.72)};
  }
  90% {
    transform: scale(2.7);
    box-shadow: 0 0 0px 15px ${alpha(Colors.primary.main, 0.01)};
  }
  95% {
    transform: scale(2.7);
    box-shadow: 0 0 0px 15px ${alpha(Colors.primary.main, 0.0)};
  }
  100% {
    transform: scale(0.95);
    box-shadow: 0 0 0px 0 ${alpha(Colors.primary.main, 0.0)};
  }
`;

const pulse1 = keyframes(waveEffect);
const pulse2 = keyframes(waveEffect);
const pulse3 = keyframes(waveEffect);

const PulsingWaves = ({ sx }: PulsingWavesProps) => {
  return (
    <Box
      sx={{
        position: 'relative',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        ...sx
      }}>
      <Box
        sx={{
          position: 'absolute',
          background: 'rgba(219, 128, 255, 0.02)',
          borderRadius: '50%',
          boxShadow: '0 0 0 0 rgba(255, 255, 255, 0)',
          height: '20px',
          width: '20px',
          transform: 'scale(1)',
          animation: `${pulse1} 2s infinite`
        }}
      />
      <Box
        sx={{
          position: 'absolute',
          background: 'rgba(219, 128, 255, 0.02)',
          borderRadius: '50%',
          boxShadow: '0 0 0 0 rgba(255, 255, 255, 0)',
          height: '20px',
          width: '20px',
          transform: 'scale(1)',
          animation: `${pulse2} 2.6s infinite`
        }}
      />
      <Box
        sx={{
          position: 'absolute',
          background: 'rgba(219, 128, 255, 0.02)',
          borderRadius: '50%',
          boxShadow: '0 0 0 0 rgba(255, 255, 255, 0)',
          height: '20px',
          width: '20px',
          transform: 'scale(1)',
          animation: `${pulse3} 1.1s infinite`
        }}
      />
    </Box>
  );
};

export default PulsingWaves;
