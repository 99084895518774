export enum ChatEvent {
  START = 'chat-start',
  RESTART = 'chat-restart',
  MESSAGE = 'chat-message',
  MESSAGE_AUDIO_CHUNK = 'chat-message-audio-chunk',
  USER_MESSAGE = 'chat-user-message',
  USER_RETRY_MESSAGE = 'chat-retry-user-message',
  USER_MESSAGE_CONFIRMED = 'chat-user-message-confirmed',
  USER_AUDIO_MESSAGE = 'chat-user-audio-message',
  ERROR = 'chat-error',
  MESSAGE_ERROR = 'chat-message-error'
}
