import { useSelector } from 'react-redux';

import { Box } from '@mui/material';

import { Colors } from '../../../design/theme';
import { chatSelector } from '../../../redux/chat/chat.selector';
import AudioChatBox from './AudioChatBox';
import TextChatBox from './TextChatBox';

const ChatContainer = () => {
  const { mode } = useSelector(chatSelector);

  return (
    <Box
      sx={{
        width: '100%',
        height: '100%',
        overflowY: 'auto',
        '&::-webkit-scrollbar': {
          scrollbarWidth: 'none',
          width: '8px',
          borderRadius: '6px'
        },
        '&::-webkit-scrollbar-thumb': {
          backgroundColor: Colors.grey,
          borderRadius: '60px',
          width: '2px'
        }
      }}>
      {mode === 'text' && <TextChatBox />}
      {mode === 'voice' && <AudioChatBox />}
    </Box>
  );
};

export default ChatContainer;
