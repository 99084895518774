import affirmationsPrev from 'assets/images/hub/affirmations-prev.png';
import avatarPrev from 'assets/images/hub/avatar-prev.png';
import meditationsPrev from 'assets/images/hub/guided-meditation.png';
import visualizationsPrev from 'assets/images/hub/visualization-prev.png';
import { useAppSelector } from 'hooks/useAppSelector';
import useIsLargeScreen from 'hooks/useIsLargeScreen';
import React, { useState } from 'react';

import {
  Box,
  Button,
  Grid,
  Typography,
  alpha,
  useMediaQuery
} from '@mui/material';

import theme, { Colors } from 'design/theme';

import { userSelector } from 'redux/user/user.selector';

import { UserSubscription } from 'types/roles.enum';

import { User } from '../../../types/user.entity';
import { Product } from '../enums/product.enum';
import { ProductOption } from '../interfaces/product-option.interface';

interface CatalogHomeProps {
  onOptionSelect: (option: Product) => void;
}
const serviceEnv = process.env.REACT_APP_SERVICE ?? 'PRODUCTION';

const catalogProducts: ProductOption[] = [
  {
    product: Product.AVATAR,
    title: 'Explore on your own',
    description: 'Smart assistant to discover your best manifestation tool',
    tip: 'Ideal for personal exploration',
    image: avatarPrev,
    time: 3,
    isReady: serviceEnv === 'STAGING'
  },
  {
    product: Product.AFFIRMATIONS,
    title: 'Affirmations',
    description: 'Daily intentions to set your day',
    tip: 'Good for shifting mindset',
    image: affirmationsPrev,
    time: 3,
    isReady: true
  },
  {
    product: Product.VISUALIZATIONS,
    title: 'Visualizations',
    description: 'Picture your desired outcome',
    tip: 'Great to live and feel your goal',
    image: visualizationsPrev,
    time: 3,
    isReady: true
  },
  {
    product: Product.MEDITATIONS,
    title: 'Guided Meditations',
    description: 'Relax and align your energy',
    tip: 'Best for focused intention',
    image: meditationsPrev,
    time: 3,
    isReady: true
  }
];

export const CatalogHome: React.FC<CatalogHomeProps> = ({ onOptionSelect }) => {
  const isLargeScreen = useIsLargeScreen();
  const [selectedOption, setSelectedOption] = useState<Product>();
  const userInfo = useAppSelector(userSelector).ui.userInfo;
  const isSmallScreen = useMediaQuery(theme.breakpoints.up(430));

  const handleEvent = (value: Product) => {
    setSelectedOption(value);
    onOptionSelect(value);
  };
  const handleScrollDown = () => {
    window.scrollTo({
      top: document.body.scrollHeight,
      behavior: 'smooth'
    });
  };

  const checkUserAccess = (
    user: Partial<User> | undefined,
    isProductReady: boolean
  ): boolean => {
    if (!user) return false;

    return (
      (isProductReady &&
        user?.subscription === UserSubscription.FREEMIUM &&
        !(user?.credits && user.credits > 1)) ||
      (isProductReady && user?.subscription === UserSubscription.PREMIUM)
    );
  };

  const isSelected = (value: ProductOption) => selectedOption === value.product;

  return (
    <Grid container spacing={2.5} sx={{ padding: 0 }}>
      {catalogProducts.map((option) => (
        <Grid item xs={12} key={option.product} sx={{ padding: 0 }}>
          <Button
            data-testid="productOption"
            sx={{
              padding: 0,
              overflow: 'hidden',
              height: '8.125rem',
              width: '100%',
              background: isSelected(option)
                ? Colors.green.light
                : Colors.white,
              borderRadius: '0.5rem',
              display: 'flex',
              color: Colors.green.main,
              border: `1px solid ${
                isSelected(option) ? Colors.green.main : 'none'
              }`,
              '&:hover': {
                background: Colors.green.light,
                color: Colors.green.dark
              },
              '&:active': {
                boxShadow: 'inset 0px 2px 0px 0px rgba(255, 255, 255, 0)',
                background: Colors.green.light,
                color: Colors.green.dark
              },
              '&.Mui-disabled': {
                background: Colors.grey,
                color: Colors.grey,
                opacity: 0.5
              },
              '&.Mui-disabled:hover': {
                cursor: 'not-allowed'
              },
              boxShadow: `0px 2px 1px -1px ${alpha(Colors.primary.dark, 0.5)}`
            }}
            onClick={() => {
              handleEvent(option.product);
              handleScrollDown();
            }}
            disabled={!checkUserAccess(userInfo, option.isReady)}>
            {isSmallScreen && (
              <Box
                sx={{
                  position: 'relative',
                  width: isLargeScreen ? '12.5rem' : '8rem',
                  height: '100%'
                }}>
                <Box
                  component="img"
                  src={option.image}
                  alt={option.title}
                  sx={{
                    height: '100%',
                    width: isLargeScreen ? '12.5rem' : '8.25rem',
                    objectFit: 'cover'
                  }}
                />
                <Box
                  sx={{
                    position: 'absolute',
                    top: '1rem',
                    left: '1rem',
                    background: Colors.lightGreen,
                    height: '1.625rem',
                    width: 'auto',
                    borderRadius: '1rem',
                    paddingX: '0.5rem',
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center'
                  }}>
                  <Typography
                    sx={{
                      fontFamily: 'Nunito',
                      fontSize: '0.875rem',
                      fontWeight: 500,
                      color: Colors.primary.main
                    }}>
                    {option.time} min
                  </Typography>
                </Box>
              </Box>
            )}

            <Box
              sx={{
                display: 'flex',
                flexDirection: 'column',
                justifyContent: isSmallScreen ? 'flex-start' : 'center',
                alignItems: 'start',
                height: '100%',
                paddingY: '1rem',
                width: '100%',
                marginLeft: isLargeScreen ? '1.5rem' : '1rem',
                paddingRight: '1rem',
                textAlign: 'left'
              }}>
              <Typography
                sx={{
                  fontFamily: 'Nunito',
                  fontSize: '0.75rem',
                  fontWeight: 500,
                  color: Colors.lightGreyGreen,
                  marginBottom: isLargeScreen ? '0.45rem' : 0
                }}>
                {option.tip}
              </Typography>
              <Typography
                sx={{
                  fontFamily: 'Nunito',
                  fontSize: '1.25rem',
                  fontWeight: 500,
                  color: Colors.black
                }}>
                {option.title}
              </Typography>

              <Typography
                sx={{
                  fontFamily: 'Nunito',
                  fontSize: '1rem',
                  fontWeight: 500,
                  color: Colors.lightGreyGreen
                }}>
                {option.description}
              </Typography>
            </Box>
          </Button>
        </Grid>
      ))}
    </Grid>
  );
};
