import { useEffect, useRef } from 'react';
import ReactMarkdown from 'react-markdown';
import { useSelector } from 'react-redux';

import { Box, Divider, Paper } from '@mui/material';

import { Colors } from '../../../design/theme';
import { chatSelector } from '../../../redux/chat/chat.selector';
import { ChatMessage } from '../types/chat-message.type';
import StatusAvatarPicture from './StatusAvatarPicture';
import ErrorAction from './ui/ErrorAction';
import PulsingDots from './ui/PulsingDotsIcon';
import PulsingWaves from './ui/PulsingWaves';

interface ChatAudioBoxMobileProps {
  lastMessage: ChatMessage | null;
}

const ChatAudioBoxMobile = ({ lastMessage }: ChatAudioBoxMobileProps) => {
  const { isRecording, isPlayingAudio, isLoadingResponse } =
    useSelector(chatSelector);
  const chatEndRef = useRef<HTMLDivElement | null>(null);

  useEffect(() => {
    chatEndRef.current?.scrollIntoView({ behavior: 'smooth' });
  }, [lastMessage, isRecording, isLoadingResponse]);

  return (
    <Box
      sx={{
        width: '100%',
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        overflow: 'hidden',
        height: `calc(100dvh - ${isRecording ? '19rem' : '16.5rem'})`
      }}>
      <Box
        sx={{
          position: 'relative',
          width: '100%',
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          paddingY: '3.375rem'
        }}>
        {isPlayingAudio && (
          <Box
            sx={{
              position: 'absolute'
            }}>
            <PulsingWaves />
          </Box>
        )}

        <StatusAvatarPicture
          sx={{ height: 84, width: 84, position: 'relative' }}
        />
      </Box>

      <Paper
        sx={{
          width: '90%',
          maxWidth: '90%',
          height: 'auto',
          fontSize: '1rem',
          fontFamily: 'Nunito',
          fontWeight: 500,
          textAlign: 'left',
          paddingX: '1rem',
          paddingTop: '0.5rem',
          paddingBottom: lastMessage?.error ? '0.5rem' : '1rem',
          borderRadius: '1rem',
          backgroundColor: Colors.softWhite,
          color: Colors.neutralGrey,
          overflowY: 'auto',
          marginBottom: '1rem',
          '&::-webkit-scrollbar': {
            scrollbarWidth: 'none',
            width: '4px',
            borderRadius: '6px'
          },
          '&::-webkit-scrollbar-thumb': {
            backgroundColor: Colors.grey,
            borderRadius: '6px',
            width: '2px'
          }
        }}>
        {isLoadingResponse ? (
          <PulsingDots
            sx={{
              marginTop: '1rem',
              width: '100%'
            }}
          />
        ) : (
          <>
            <ReactMarkdown>{lastMessage?.text}</ReactMarkdown>
            {lastMessage?.error && (
              <>
                {lastMessage.text && <Divider />}
                <ErrorAction
                  messageError={lastMessage.error}
                  messageId={lastMessage.id}
                />
              </>
            )}
            <div ref={chatEndRef} />
          </>
        )}
      </Paper>
    </Box>
  );
};

export default ChatAudioBoxMobile;
